import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'
import Container from '../Container'
import { BackgroundSectionStyle } from './backgroundSection.css.js'

const defaultProps = {
  centered: false,
}

const propTypes = {
  children: PropTypes.node.isRequired,
  centered: PropTypes.bool,
}
const SectionAlternate = styled.section`
  ${ BackgroundSectionStyle };
  color: ${ props => props.theme.white };
  background-color: ${ ({ theme }) => theme.portGore };
  text-align: ${ ({ textCentered }) => (textCentered ? 'center' : 'initial') };
  h1,
  h2,
  h3,
  h4,
  p {
    color: ${ props => props.theme.white };
  }
`

const Alternate = props => {
  const { children, centered } = props
  return (
    <SectionAlternate textCentered={centered}>
      <Container>
        <Row>
          <Col xs={12}>{children}</Col>
        </Row>
      </Container>
    </SectionAlternate>
  )
}

Alternate.defaultProps = defaultProps
Alternate.propTypes = propTypes

export default Alternate
