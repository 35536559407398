import { css } from 'styled-components'
import { calculateRem } from '../../utils/javascript-styles'

export const BackgroundSectionStyle = css`
  background-size: cover;
  position: relative;
  z-index: 100;
  background-repeat: no-repeat;

  color: white;
  padding-top: ${ calculateRem(80) };
  padding-right: ${ calculateRem(16) };
  padding-bottom: ${ calculateRem(80) };
  padding-left: ${ calculateRem(16) };

  h1 {
    font-size: ${ calculateRem(58) };
    font-weight: 700;
  }
  h2 {
    font-size: ${ calculateRem(48) };
    font-weight: 700;
  }
  h3 {
    font-size: ${ calculateRem(38) };
  }
  h4 {
    font-size: ${ calculateRem(22) };
  }
  h1,
  h2,
  h3,
  h4 {
    margin-top: 0;
  }
  img {
    z-index: 100;
  }
  blockquote {
    font-size: ${ calculateRem(38) };
    text-shadow: 1px 1px 0 ${ props => props.theme.black };
    font-style: italic;
  }
  /* Small only */
  @media screen and (max-width: 39.9375em) {
    h1 {
      font-size: ${ calculateRem(48) };
    }
    h2 {
      font-size: ${ calculateRem(38) };
    }
    h3 {
      font-size: ${ calculateRem(28) };
    }
    h4 {
      font-size: ${ calculateRem(18) };
    }
    blockquote {
      font-size: ${ calculateRem(28) };
    }
  }
`
