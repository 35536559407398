import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'
import Container from '../Container'
import { BackgroundSectionStyle } from './backgroundSection.css.js'

const defaultProps = {
  centered: false,
}

const propTypes = {
  children: PropTypes.node.isRequired,
  centered: PropTypes.bool,
}

const SectionPrimary = styled.section`
  ${ BackgroundSectionStyle };
  text-align: ${ ({ textCentered }) => (textCentered ? 'center' : 'initial') };

  color: ${ props => props.theme.oil };
  h1,
  h2,
  h3 {
    color: ${ props => props.theme.denim };
  }
`

const Primary = props => {
  const { children, centered } = props
  return (
    <SectionPrimary textCentered={centered}>
      <Container>
        <Row>
          <Col xs={12}>{children}</Col>
        </Row>
      </Container>
    </SectionPrimary>
  )
}

Primary.defaultProps = defaultProps
Primary.propTypes = propTypes

export default Primary
